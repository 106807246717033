import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import sdg from '../assets/images/sdg.jpg'
import ato from '../assets/images/ato.jpg'
import mit from '../assets/images/mit.jpg'
import agentic from '../assets/images/agentic2016.jpg'
import dinner from '../assets/images/dinner.jpg'
import cannabis from '../assets/images/cannabis.jpg'
import liveworx from '../assets/images/liveworx2017.jpg'
import reconnectfood from '../assets/files/reconnectfood.pdf'
import sdgarticle from '../assets/files/SDG.pdf'
import cannmed19 from '../assets/images/cannmed19.jpg'
import edenprotocol from '../assets/images/EDENField.png'
import liberland from '../assets/images/liberland.png'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Prior Works & References</title>
            <meta name="description" content="Prior Works" />
        </Helmet>

        <BannerLanding />

        <div id="main">
          <section id="two" className="spotlights">
            <section id="edenprotocol">
              <a href="https://edenprotocol.io" className="image" target="other">
                  <img src={edenprotocol} alt="" height="100%"/>
              </a>
              <div className="content">
                  <div className="inner">
                      <header className="major">
                          <h3>2021 - The EDEN Protocol: Ethical Data Exchange Network</h3>
                      </header>
                      <p><a href="https://edenprotocol.io" target="other">EDEN</a> is a public suite of protocols designed to facilitate exchange of information between supply-chain member-participants. The EDEN protocol enables P2P data interoperability between proprietary applications and systems.</p>
                  <ul className="actions">
                      <li><a href="https://edenprotocol.io" className="button" target="other">Visit EDENProtocol.io</a></li>
                  </ul>
                  </div>
              </div>
            </section>
            <section id="lexinomicon">
                <a href="https://www.youtube.com/watch?v=SzqNoe2zHxw" className="image" target="other">
                    <img src={liberland} alt="" height="100%"/>
                </a>
                <div className="content">
                    <div className="inner">
                        <header className="major">
                            <h3>2020 - Startup Societies and the ULEX Legal System</h3>
                        </header>
                        <p><a href="https://www.youtube.com/watch?v=SzqNoe2zHxw" target="other">Official Liberland Presentation Channel</a>. Conference in VR where I present the evolving concept of decentralized legal systems. More details at <a href="https://ulex.law" target="other">ULEX.law</a></p>

                    <ul className="actions">
                        <li><a href="https://ulex.law" className="button" target="other">Visit ULEX.law</a></li>
                    </ul>
                    </div>
                </div>
              </section>
              <section id="cannmed">
                <a href="https://www.youtube.com/watch?v=DYW5O1lnWV4" className="image" target="other">
                    <img src={cannmed19} alt="" height="100%"/>
                </a>
                <div className="content">
                    <div className="inner">
                        <header className="major">
                            <h3>2019 - CannMed19 - Protecting IP with Blockchain Technology</h3>
                        </header>
                        <p><a href="https://www.youtube.com/watch?v=DYW5O1lnWV4" target="other">CannMed19 - IP Breeders Rights Panel</a> Using blockchain technology and peer-to-peer networks to protect data, methods, and other forms of prior arts.</p>
                      <ul className="actions">
                          <li><a href="https://www.youtube.com/watch?v=DYW5O1lnWV4" className="button" target="other">Watch on YouTube</a></li>
                      </ul>
                    </div>
                </div>
              </section>
              <section id="equibit">
                  <a href="https://www.rt.com/shows/keiser-report/429484-episode-max-keiser-1239/" className="image" target="other">
                      <img src={dinner} alt="" />
                  </a>
                  <div className="content">
                      <div className="inner">
                          <header className="major">
                              <h3>2018 - equibit.org - A Peer-to-Peer Electronic Equity System</h3>
                          </header>
                          <p> Designed, implemented, and launched Testnet for <a href="https://equibit.org" target="other">Equibit crypto-securities protocol</a>. The protocol is a fork of Bitcoin augmented with various enhancements and new transaction types to support the issuance of securities. Completely peer-to-peer with multi-sig web-of-trust for 3rd party AML/KYC compliance.</p>
                          <ul className="actions">
                              <li><a href="https://arxiv.org/abs/1612.06953" className="button" target="other">Whitepaper: P2P Electronic Equity System</a></li>
                          </ul>
                      </div>
                  </div>
              </section>
              <section id="ripe">
                  <a href={reconnectfood} className="image">
                      <img src={liveworx} alt="" />
                  </a>
                  <div className="content">
                      <div className="inner">
                          <header className="major">
                              <h3>2017 - ripe.io - The Blockchain of Food</h3>
                          </header>
                          <p><a href="https://www.forbes.com/sites/maggiemcgrath/2017/06/28/the-25-most-innovative-ag-tech-startups/#1b274c1e4883" target="other">Forbes Top-25</a> Most Innovative AgTech startup of 2017. One of the earliest patent (2017) in the IOT + blockchain space (<a href="https://patents.google.com/patent/US20180285810A1" target="other">US20180285810</a>). See how <a href="https://bitcoinexchangeguide.com/maersk-growth-leads-ripe-technology-ripe-io-2-4-million-investment-for-blockchain-food-tracking/" target="other">Maersk</a> is using the Blockchain of Food in 2019.</p><p>This radical breakthrough for agriculture and food supply uses blockchain technology to follow the history of food from a seed's genetic composition to the flavor of a tomato in our plate. Blockchain solves supply-chain coordination and information exchange problems that couldn't be solved before.</p>
                          <ul className="actions">
                              <li><a href={reconnectfood} className="button">Whitepaper: Reconnecting Food</a></li>
                          </ul>
                      </div>
                  </div>
              </section>
              <section id="ato">
                  <a href="https://allthingsopen.org/app/uploads/2016/10/ato2016thurs.pdf" className="image">
                      <img src={ato} alt="" />
                  </a>
                  <div className="content">
                      <div className="inner">
                          <header className="major">
                              <h3>2016, 2017 - All Things Open, Raleigh NC</h3>
                          </header>
                          <p>2017: Presentation of a sample design for a hospitality blockchain creating autonomous entities representing sleeping rooms available on a network. 2016: Discuss the importance of open source software (OSS) and encryption technology.</p>
                          <ul className="actions">
                              <li><a href="https://allthingsopen.org/app/uploads/2016/10/ato2016thurs.pdf" className="button">All Things Open Agenda</a></li>
                          </ul>
                      </div>
                  </div>
              </section>
              <section id="mit">
                  <a href="https://therealreporter.com/briefs/mits_real_disruption_series_talks_block_chain_for_real_estate" className="image" target="other">
                      <img src={mit} alt="" />
                  </a>
                  <div className="content">
                      <div className="inner">
                          <header className="major">
                              <h3>2016 - MIT Center for Real Estate: Real Disruption Series</h3>
                          </header>
                          <p>MIT "Real Disruption" series. Christian Saucier presents the value of decentralized ledgers for public registries. After the presentation, an impromptu lunch in organized at the MIT Media Lab where Christian explains the technologies behind blockchain systems to a hungry audience of MIT students.</p>
                          <ul className="actions">
                              <li><a href="https://therealreporter.com/briefs/mits_real_disruption_series_talks_block_chain_for_real_estate" className="button" target="other">Read About It</a></li>
                          </ul>
                      </div>
                  </div>
              </section>
              <section id="kannapedia">
                  <a href="https://www.medicinalgenomics.com/kannapedia/" className="image" target="other">
                      <img src={cannabis} alt="" />
                  </a>
                  <div className="content">
                      <div className="inner">
                          <header className="major">
                              <h3>2016 - <a href="https://www.medicinalgenomics.com/kannapedia/" target="other">Kannapedia.net by Medicinal Genomics</a></h3>
                          </header>
                          <p>Designed the original integration of <a href="https://www.kannapedia.net" target="other">Kannapedia.net</a> with Bitcoin blockchain for genetic record certification. They have since re-integrated with DASH and are regular participants in the DASH DAO. Fantastic real application of blockchain technology for the growing cannabis industry.</p>
                          <ul className="actions">
                              <li><a href="https://kannapedia.net" className="button" target="other">Visit Kannapedia</a></li>
                          </ul>
                      </div>
                  </div>
              </section>
              <section id="agentic">
                <a href="https://cointelegraph.com/news/how-the-blockchain-will-change-the-real-estate-industry" className="image" target="other">
                    <img src={agentic} alt="" />
                </a>
                <div className="content">
                    <div className="inner">
                        <header className="major">
                            <h3>2016 - Real Estate Industry Applications</h3>
                        </header>
                        <p>Designed and prototyped decentralized real-estate registration system using the Bitcoin blockchain. This application has since been grown by the client and operates various pilots around the world.</p>
                        <ul className="actions">
                            <li><a href="https://cointelegraph.com/news/how-the-blockchain-will-change-the-real-estate-industry" className="button" target="other">CoinTelegraph Article</a></li>
                        </ul>
                    </div>
                </div>
              </section>
              <section id="archetech">
                  <a href={sdgarticle} className="image">
                      <img src={sdg} alt="" />
                  </a>
                  <div className="content">
                      <div className="inner">
                          <header className="major">
                              <h3>2015 - Archetech Distributed</h3>
                          </header>
                          <p>Archetech Distributed designs decentralized collaboration systems. The Nomicon collaboration protocol and Vikiwiki engine are projects that remain alive today in various forms. A recent example is the Cryptonomicon collaboration wiki being used as a platform to launch the Equibit Testnet in 2019. The inspiration for these systems came in part from the 2012 Better Software article titled <a href={sdgarticle}>The Software Development Game</a>.</p>
                          <ul className="actions">
                              <li><a href={sdgarticle} className="button" target="other">Download the Article</a></li>
                          </ul>
                      </div>
                  </div>
              </section>
            </section>
        </div>

    </Layout>
)

export default Landing
